<template>
  <div>
    <loading-more :fetch-data="fetchData" />
  </div>
</template>

<script>
import LoadingMore from "./components/loading-more";
import { getRecruitList } from "@/api/company";

const recruitColumns = [
  {
    title: "招聘职位",
    dataIndex: "title",
  },
  {
    title: "城市",
    dataIndex: "city",
  },
  {
    title: "开始日期",
    dataIndex: "startDate",
  },
  {
    title: "薪水",
    dataIndex: "salary",
  },
  {
    title: "学历",
    dataIndex: "education",
  },
  {
    title: "数据来源",
    dataIndex: "source",
  },
];

export default {
  components: {
    LoadingMore,
  },
  data() {
    return {
      recruit: {
        columns: recruitColumns,
        dataSource: [],
        info: {
          round: "",
          investorName: "",
          moneyNum: "",
          date: "",
          realTime: "",
          recruitAdvisor: "",
        },
      },
      ui: {
        top: 10,
        loading: false,
        isAddAction: false,
        isEditAction: false,
        showDrawer: false,
        enableAdd: false,
        enableEdit: false,
        enableDelete: false,
      },
    };
  },
  methods: {
    async fetchData(companyId, page) {
      let formData = {
        companyId: companyId,
        page: page,
        pageSize:10
      };
      return (await getRecruitList(formData)).data;
    },
  },
};
</script>

<style lang="less" scoped>
</style>